table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #08C;
}

table.dataTable.stripe tbody > tr.odd.selected,
table.dataTable.stripe tbody > tr.odd > .selected,
table.dataTable.display tbody > tr.odd.selected,
table.dataTable.display tbody > tr.odd > .selected {
  background-color: #0085c7;
}

table.dataTable.hover tbody > tr.selected:hover,
table.dataTable.hover tbody > tr > .selected:hover,
table.dataTable.display tbody > tr.selected:hover,
table.dataTable.display tbody > tr > .selected:hover {
  background-color: #0083c5;
}

table.dataTable.order-column tbody > tr.selected > .sorting_1,
table.dataTable.order-column tbody > tr.selected > .sorting_2,
table.dataTable.order-column tbody > tr.selected > .sorting_3,
table.dataTable.order-column tbody > tr > .selected,
table.dataTable.display tbody > tr.selected > .sorting_1,
table.dataTable.display tbody > tr.selected > .sorting_2,
table.dataTable.display tbody > tr.selected > .sorting_3,
table.dataTable.display tbody > tr > .selected {
  background-color: #0085c8;
}

table.dataTable.display tbody > tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
  background-color: #0081c1;
}

table.dataTable.display tbody > tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_2 {
  background-color: #0082c2;
}

table.dataTable.display tbody > tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_3 {
  background-color: #0083c4;
}

table.dataTable.display tbody > tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
  background-color: #0085c8;
}

table.dataTable.display tbody > tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_2 {
  background-color: #0086ca;
}

table.dataTable.display tbody > tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_3 {
  background-color: #0087cb;
}

table.dataTable.display tbody > tr.odd > .selected,
table.dataTable.order-column.stripe tbody > tr.odd > .selected {
  background-color: #0081c1;
}

table.dataTable.display tbody > tr.even > .selected,
table.dataTable.order-column.stripe tbody > tr.even > .selected {
  background-color: #0085c8;
}

table.dataTable.display tbody > tr.selected:hover > .sorting_1,
table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_1 {
  background-color: #007dbb;
}

table.dataTable.display tbody > tr.selected:hover > .sorting_2,
table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_2 {
  background-color: #007ebd;
}

table.dataTable.display tbody > tr.selected:hover > .sorting_3,
table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_3 {
  background-color: #007fbf;
}

table.dataTable.display tbody > tr:hover > .selected,
table.dataTable.display tbody > tr > .selected:hover,
table.dataTable.order-column.hover tbody > tr:hover > .selected,
table.dataTable.order-column.hover tbody > tr > .selected:hover {
  background-color: #007dbb;
}

table.dataTable td.select-checkbox {
  position: relative;
}

table.dataTable td.select-checkbox:before, table.dataTable td.select-checkbox:after {
  display: block;
  position: absolute;
  top: 1.2em;
  left: 50%;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
}

table.dataTable td.select-checkbox:before {
  content: ' ';
  margin-top: -6px;
  margin-left: -6px;
  border: 1px solid black;
  border-radius: 3px;
}

table.dataTable tr.selected td.select-checkbox:after {
  content: '\2714';
  margin-top: -11px;
  margin-left: -4px;
  text-align: center;
  text-shadow: 1px 1px #B0BED9, -1px -1px #B0BED9, 1px -1px #B0BED9, -1px 1px #B0BED9;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
  margin-left: 0.5em;
}

@media screen and (max-width: 640px) {
  div.dataTables_wrapper span.select-info,
  div.dataTables_wrapper span.select-item {
    margin-left: 0;
    display: block;
  }
}

table.dataTable tbody tr.selected,
table.dataTable tbody th.selected,
table.dataTable tbody td.selected {
  color: white;
}

table.dataTable tbody tr.selected a,
table.dataTable tbody th.selected a,
table.dataTable tbody td.selected a {
  color: #a2d4ed;
}

@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}

div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3;
}

div.dt-button-info > div {
  padding: 1em;
}

button.dt-button,
div.dt-button,
a.dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 0.333em;
  margin-bottom: 0.333em;
  padding: 0.5em 1em;
  border: 1px solid #999;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  line-height: 1.6em;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  background-color: #e9e9e9;
  /* Fallback */
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  /* FF3.6 */
  /* IE10 */
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, white 0%, #e9e9e9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='white', EndColorStr='#e9e9e9');
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
}

button.dt-button.disabled,
div.dt-button.disabled,
a.dt-button.disabled {
  color: #999;
  border: 1px solid #d0d0d0;
  cursor: default;
  background-color: #f9f9f9;
  /* Fallback */
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  /* FF3.6 */
  /* IE10 */
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #ffffff 0%, #f9f9f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#ffffff', EndColorStr='#f9f9f9');
}

button.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled),
div.dt-button:active:not(.disabled),
div.dt-button.active:not(.disabled),
a.dt-button:active:not(.disabled),
a.dt-button.active:not(.disabled) {
  background-color: #e2e2e2;
  /* Fallback */
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  /* FF3.6 */
  /* IE10 */
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #f3f3f3 0%, #e2e2e2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f3f3f3', EndColorStr='#e2e2e2');
  box-shadow: inset 1px 1px 3px #999999;
}

button.dt-button:active:not(.disabled):hover:not(.disabled), button.dt-button.active:not(.disabled):hover:not(.disabled),
div.dt-button:active:not(.disabled):hover:not(.disabled),
div.dt-button.active:not(.disabled):hover:not(.disabled),
a.dt-button:active:not(.disabled):hover:not(.disabled),
a.dt-button.active:not(.disabled):hover:not(.disabled) {
  box-shadow: inset 1px 1px 3px #999999;
  background-color: #cccccc;
  /* Fallback */
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  /* FF3.6 */
  /* IE10 */
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #eaeaea 0%, #cccccc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#eaeaea', EndColorStr='#cccccc');
}

button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
  text-decoration: none;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
  border: 1px solid #666;
  background-color: #e0e0e0;
  /* Fallback */
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  /* FF3.6 */
  /* IE10 */
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f9f9f9', EndColorStr='#e0e0e0');
}

button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled) {
  border: 1px solid #426c9e;
  text-shadow: 0 1px 0 #c4def1;
  outline: none;
  background-color: #79ace9;
  /* Fallback */
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  /* FF3.6 */
  /* IE10 */
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #bddef4 0%, #79ace9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#bddef4', EndColorStr='#79ace9');
}

.dt-button embed {
  outline: none;
}

div.dt-buttons {
  position: relative;
  float: left;
}

div.dt-buttons.buttons-right {
  float: right;
}

div.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  margin-top: 3px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
  overflow: hidden;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  z-index: 2002;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}

div.dt-button-collection button.dt-button,
div.dt-button-collection div.dt-button,
div.dt-button-collection a.dt-button {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  float: none;
  margin-bottom: 4px;
  margin-right: 0;
}

div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled),
div.dt-button-collection div.dt-button:active:not(.disabled),
div.dt-button-collection div.dt-button.active:not(.disabled),
div.dt-button-collection a.dt-button:active:not(.disabled),
div.dt-button-collection a.dt-button.active:not(.disabled) {
  background-color: #dadada;
  /* Fallback */
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  /* FF3.6 */
  /* IE10 */
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f0f0f0', EndColorStr='#dadada');
  box-shadow: inset 1px 1px 3px #666;
}

div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}

div.dt-button-collection.fixed.two-column {
  margin-left: -150px;
}

div.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}

div.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}

div.dt-button-collection > * {
  break-inside: avoid;
}

div.dt-button-collection.two-column {
  width: 300px;
  padding-bottom: 1px;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}

div.dt-button-collection.three-column {
  width: 450px;
  padding-bottom: 1px;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}

div.dt-button-collection.four-column {
  width: 600px;
  padding-bottom: 1px;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}

div.dt-button-collection .dt-button {
  border-radius: 0;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* Fallback */
  /* IE10 Consumer Preview */
  /* Firefox */
  /* Opera */
  /* Webkit (Safari/Chrome 10) */
  /* Webkit (Chrome 11+) */
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* W3C Markup, IE10 Release Preview */
  z-index: 2001;
}

@media screen and (max-width: 640px) {
  div.dt-buttons {
    float: none !important;
    text-align: center;
  }
}

button.dt-button.processing,
div.dt-button.processing,
a.dt-button.processing {
  color: rgba(0, 0, 0, 0.2);
}

button.dt-button.processing:after,
div.dt-button.processing:after,
a.dt-button.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: ' ';
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}

@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}

div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3;
}

div.dt-button-info > div {
  padding: 1em;
}

ul.dt-button-collection.dropdown-menu {
  display: block;
  z-index: 2002;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}

ul.dt-button-collection.dropdown-menu.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}

ul.dt-button-collection.dropdown-menu.fixed.two-column {
  margin-left: -150px;
}

ul.dt-button-collection.dropdown-menu.fixed.three-column {
  margin-left: -225px;
}

ul.dt-button-collection.dropdown-menu.fixed.four-column {
  margin-left: -300px;
}

ul.dt-button-collection.dropdown-menu > * {
  break-inside: avoid;
}

ul.dt-button-collection.dropdown-menu.two-column {
  width: 300px;
  padding-bottom: 1px;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}

ul.dt-button-collection.dropdown-menu.three-column {
  width: 450px;
  padding-bottom: 1px;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}

ul.dt-button-collection.dropdown-menu.four-column {
  width: 600px;
  padding-bottom: 1px;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}

ul.dt-button-collection.dropdown-menu .dt-button {
  border-radius: 0;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2001;
}

@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }
  div.dt-buttons a.btn {
    float: none;
  }
}

div.dt-buttons button.btn.processing,
div.dt-buttons div.btn.processing,
div.dt-buttons a.btn.processing {
  color: rgba(0, 0, 0, 0.2);
}

div.dt-buttons button.btn.processing:after,
div.dt-buttons div.btn.processing:after,
div.dt-buttons a.btn.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: ' ';
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}

/*# sourceMappingURL=vendor_sass.css.map */
